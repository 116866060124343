var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loaded)?_c('b-spinner',{staticClass:"text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"Loading...","type":"grow"}}):_vm._e(),(_vm.loaded)?_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"editClientValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card-header',{staticClass:"mt-n1"},[_c('h4',{staticStyle:{"color":"#4D6D8A","margin-top":"0.5em"}},[_vm._v(" Edit Connection ")])]),_c('b-row',{staticStyle:{"padding":"0 21px 20px 21px"}},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"client name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"client-name"}},[_vm._v(" Connection Name ")]),_c('b-form-input',{attrs:{"id":"client-name","placeholder":"E.g. John Doe"},model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1092121087)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"client type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"client-type"}},[_vm._v(" Connection Type ")]),_c('b-form-select',{attrs:{"id":"client-type","options":_vm.clientTypes},model:{value:(_vm.clientType),callback:function ($$v) {_vm.clientType=$$v},expression:"clientType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1847495151)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"phone"}},[_vm._v(" Phone No. ")]),_c('b-form-input',{attrs:{"id":"phone","placeholder":"E.g. (+234)8245 789 7988"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1173938398)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('b-form-input',{attrs:{"id":"email","placeholder":"E.g. johndoe@email.com","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"load","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"load"}},[_vm._v(" Load ")]),_c('b-form-input',{attrs:{"id":"load","placeholder":"E.g. 100kWh"},model:{value:(_vm.load),callback:function ($$v) {_vm.load=$$v},expression:"load"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1020048728)})],1)],1),_c('b-col',{staticClass:"mt-lg-0 mt-1",attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"house-size"}},[_vm._v(" House Size ")]),_c('b-form-select',{attrs:{"id":"house-size","options":_vm.houseSizes},model:{value:(_vm.houseSize),callback:function ($$v) {_vm.houseSize=$$v},expression:"houseSize"}})],1)],1)],1)],1),_c('b-col',{staticClass:"mt-lg-0 mt-1",attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"address"}},[_vm._v(" Address ")]),_c('b-form-input',{attrs:{"id":"address"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1303801290)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"state"}},[_vm._v(" State ")]),_c('b-form-select',{attrs:{"id":"state","options":_vm.states},on:{"change":function($event){return _vm.getLGAsByStateID()}},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{staticClass:"mb-lg-1 font-weight-bolder",attrs:{"for":"lga"}},[_vm._v(" LGA ")]),_c('b-form-select',{attrs:{"id":"lga","options":_vm.lgas},model:{value:(_vm.lga),callback:function ($$v) {_vm.lga=$$v},expression:"lga"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex float-right"},[_c('b-button',{staticStyle:{"background-color":"#C4C4C4 !important","border-color":"#C4C4C4 !important","border-radius":"40px","margin-right":"1em"},on:{"click":function($event){return _vm.$router.push('/projects/view-mesh-project')}}},[_vm._v(" Cancel ")]),(!_vm.isLoading)?_c('b-button',{staticStyle:{"background-color":"#4D6D8A !important","border-color":"#4D6D8A !important","border-radius":"40px"},attrs:{"type":"submit"},on:{"click":_vm.editClient}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticStyle:{"background-color":"#4D6D8A !important","border-color":"#4D6D8A !important","border-radius":"40px"},attrs:{"disabled":""}},[_vm._v(" Saving... ")]):_vm._e()],1)])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }